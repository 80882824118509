@tailwind base;

@tailwind components;

@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito', sans-serif;
}

html {
    scroll-behavior: smooth;
}

/* add the code bellow */
@layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}

/* The animations definition */
@keyframes fadeScreen1 {
    from {
        opacity: 100;
    }
    to {
        opacity: 0;
    }
}
@keyframes fadeScreen2 {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}

/* Animation binding classes */
.screen1-fade-binder {
    animation: fadeScreen1 3s ease-in-out 2s infinite alternate;
}
.screen2-fade-binder {
    animation: fadeScreen2 3s ease-in-out 2s infinite alternate;
}
